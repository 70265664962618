import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import { PostGrid } from '../components/PostGrid';

const topicTemplate = props => {
  const { data } = props;
  const topic = props.pageContext.topic.toUpperCase();
  const posts = data.allMdx.edges;

  return (
    <Layout>
      <SEO title={`${topic} Posts`} />
      <PostGrid heading={topic} posts={posts}></PostGrid>
    </Layout>
  );
};

export const query = graphql`
  query getTopicPosts($topic: String!) {
    allMdx(
      filter: { frontmatter: { topic: { eq: $topic } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
            topic
            summary
          }
        }
      }
    }
  }
`;

export default topicTemplate;
